(() => {
    const initialize_form = () => {
        $('.js-form__optional-row').each(function() {
            const $inputs = $(this).find('input');
            let filled = false;
            $inputs.each((index, element) => {
                if ($(element).val()) {
                    filled = true;
                    console.log($(element).val());
                    return false;
                }
            });
            if (filled) {
                $(this).find('.js-form__row-to-hide-wrapper').show();
            }
            else {
                $(this).find('.js-form__add-row').show();
                return false;
            }
        });
        $('.js-form__add-row-link').click(function () {
            const $parent = $(this).closest('.js-form__optional-row');
            $parent.find('.js-form__row-to-hide-wrapper').slideDown();
            $parent.find('.js-form__add-row').slideUp();
            $parent.next().find('.js-form__add-row').slideDown();
        });
    };

    initialize_form();
    $('body').on('submit', '.js-form--application', function(event) {
        const data = $(this).serialize();
        const action = $(this).attr('action');
        $.post(
            action,
            data,
            function (data) {
                $('.js-form-wrapper--application').replaceWith(data);
                initialize_form();
            }
        );
        event.preventDefault();
    });

    const training = new URLSearchParams(window.location.search).get('schulung');
    if (training) {
        $(`#id_training option[value=${training}]`).prop('selected', 'selected');
    }
})();

(() => {
    let category = undefined;
    let name = '';
    const members = $('.js-team-member-card');
    const stripes =  $('.js-team-member-card + .solutions-section');

    function enumerate_visible_items() {
        let column = 1;
        let $bubble;
        for (let member of members) {
            if ($(member).is(':visible')) {
                $bubble = $(member).find('.bubble');
                $bubble
                    .removeClass('bubble--1-of-3')
                    .removeClass('bubble--2-of-3')
                    .removeClass('bubble--3-of-3')
                    .addClass(`bubble--${column}-of-3`);
                if (column === 3) {
                    column = 1;
                } else {
                    column++;
                }
            }
        }
    }

    function filter_entries() {
        let hidden = 0;
        category = $('.js-team-fachbereich-filter select option:selected').attr('value');
        name = $('.js-team-name-filter input').val().replace(/\s/, '').toLowerCase();
        for (let member of members) {
            const $member = $(member);
            $member.show();
            if (category) {
                const member_categories = $member.attr('data-categories').split(',');
                if (!member_categories.includes(category)) {
                    $member.hide();
                    hidden += 1;
                }
            }
            if (name) {
                const member_name = $member.find('.js-team-member-name').text().replace(/\s/, '').toLowerCase();
                if (member_name.indexOf(name) === -1) {
                    $member.hide();
                    hidden += 1;
                }
            }
        }
        if (hidden > 0) {
            stripes.hide();
            enumerate_visible_items();
        }
        else {
            stripes.show();
            enumerate_visible_items();
        }
    }

    if (members.length > 0) {
        filter_entries();
        $('.js-team-fachbereich-filter select').on('change', filter_entries);
        $('.js-team-name-filter input').on('keyup', filter_entries);
    }
})();

(() => {
    let category = undefined;
    let industry = '';
    const logos = $('.js-logo-card');

    function filter_entries() {
        category = $('.js-logo-categories option:selected').attr('value');
        industry = $('.js-logo-industry option:selected').attr('value');
        for (let logo of logos) {
            const $logo = $(logo);
            $logo.show();
            if (category) {
                const logo_categories = $logo.attr('data-categories').split(',');
                if (!logo_categories.includes(category)) {
                    $logo.hide();
                }
            }
            if (industry) {
                const logo_industry = $logo.attr('data-industry');
                if (logo_industry !== industry) {
                    $logo.hide();
                }
            }
        }
    }

    if (logos) {
        filter_entries();
        $('.js-logo-filters select').on('change', filter_entries);
    }
})();